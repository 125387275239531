import React, {FC, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useAppNavigation from '@src/utils/useAppNavigation';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import useDurationFactory from '@src/Hooks/useDurationFactory';
import useUpdateBookmarks from '@src/Hooks/useUpdateBookmarks';
import {
  ICollectionFragmentFragment,
  IPaywallFragmentFragment,
  IVodItemFragmentFragment,
  useCollectionByUrlEndLazyQuery,
} from '@src/Api/Graphql/gql/types.generated';
import {useFindProgression} from '@src/Hooks/useFindProgression';
import usePaywal from '@src/Hooks/usePaywal';
import {AppRoutesEnum} from '@src/Constant/routes';
import {SingleContentLoader} from 'design-system/src/Components/ContentLoader';
import SingleHeader from 'design-system/src/Widget/SingleHeader/SingleHeader.web';
import {View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import RelatedCategory from '@src/components/RelatedCategory/RelatedCategory';
import AppDropDownMenuModal from '@src/App/components/Common/AppDropDownMenuModal/AppDropDownMenuModal';
import SharedCard from 'design-system/src/Components/Card/SharedCard/SharedCard.web';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import appSlice from '@src/Store/Slices/AppSlice';
import {useAppDispatch} from '@src/Store/hooks';
import CustomModalHeader from '@src/components/general/CustomModal/CustomModalHeader';
import useCastOnPage from '../hooks/useCastOnPage';
import useShareContent from '@src/Hooks/useShareContent';
import useGetLinkToShare from '@src/utils/useGetLinkToShare';
import useParams from '@src/Hooks/useParams';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';

type ISeason = Record<string, IVodItemFragmentFragment[]>;
interface ISeasonItem {
  title: string;
  num: string;
}

const VodCollectionPage: FC = () => {
  const params = useParams();
  const {endUrl1, endUrl2} = params;
  const endUrl = `/${endUrl1}/${endUrl2}/`.replaceAll('//', '/');

  const {t} = useTranslation('translation');
  const [currentSeason, setCurrentSeason] = useState<string>();
  const navigation = useAppNavigation();
  const {isMobile} = useBreakpoint();
  const tracker = useTracker();
  const {parseDuration} = useDurationFactory();
  const {isBookmarked, updateBookMark} = useUpdateBookmarks({
    screenSource: 'detail',
  });
  const [fetchCollectionByUrlEnd, {data, loading}] =
    useCollectionByUrlEndLazyQuery();
  const {findCurrentTime, findDuration, findProgression} = useFindProgression();
  const dispatch = useAppDispatch();

  const shareContent = useShareContent({screenSource: 'detail'});
  const [isOpenDialogShare, setIsOpenDialogShare] = useState(false);
  const [linkToShare, setLinkToShare] = useState<string | undefined>(undefined);
  const {getLinkToShare} = useGetLinkToShare();

  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();

  function calculateWidth() {
    var viewportWidth = window.innerWidth; // Get the viewport width in pixels
    var result = viewportWidth - 0.2 * viewportWidth; // Subtract 20% of the viewport width
    return result; // Return the result in pixels
  }

  useEffect(() => {
    if (endUrl) {
      fetchCollectionByUrlEnd({
        variables: {
          endUrl,
        },
        errorPolicy: 'all',
      });
    }
  }, [endUrl]);

  const {
    collection,
    lastWatchedItem,
    lastWatchedItemNumber,
    season,
    seasonList,
  }: {
    collection: ICollectionFragmentFragment | undefined;
    lastWatchedItem?: IVodItemFragmentFragment;
    lastWatchedItemNumber?: number;
    season: ISeason;
    seasonList: ISeasonItem[];
  } = useMemo(() => {
    let element: ICollectionFragmentFragment | undefined;
    let _lastWatchedItem: IVodItemFragmentFragment | undefined;
    let _lastWatchedItemNumber: number = 1;
    const _perSeason: ISeason = {};
    const _collection = data?.allCollection?.items[0];

    if (_collection && _collection.children && _collection.children.items) {
      element = _collection;

      _collection.children.items.forEach((item, index) => {
        if (item && item.__typename === 'VODItem') {
          const _currentSeason: number = item.seasonNumber || 1;
          if (_currentSeason) {
            if (!_perSeason[_currentSeason]) {
              _perSeason[_currentSeason] = [];
            }
            if (!item.episodeNumber) {
              _perSeason[_currentSeason].push({
                ...item,
                episodeNumber: _perSeason[_currentSeason].length + 1,
              });
            } else {
              _perSeason[_currentSeason].push(item);
            }

            if (!_lastWatchedItem) {
              _lastWatchedItem = item;
              _lastWatchedItemNumber = index + 1;
            }
          }
        }
      });
    }
    let _seasonList: string[] = Object.keys(_perSeason).sort((a, b) => +a - +b);

    return {
      collection: element,
      lastWatchedItem: _lastWatchedItem,
      lastWatchedItemNumber: _lastWatchedItemNumber,
      season: _perSeason,
      seasonList: _seasonList.map(el => ({
        title: t('Season {{number}}', {number: el}),
        num: el,
      })),
    };
  }, [data, t]);

  useCastOnPage({
    vimeoId: `${lastWatchedItem?.liveEventID}` || undefined,
    title: lastWatchedItem?.title,
    currentTime: 0,
  });

  const {description, title, public: collectionPublic = []} = collection || {};
  const selectedSeason: ISeasonItem | undefined = currentSeason
    ? seasonList.find(i => i.num === currentSeason) || seasonList[0]
    : seasonList[0];
  const episodes: IVodItemFragmentFragment[] = selectedSeason
    ? season[selectedSeason.num] || season['0'] || []
    : season['0'] || [];

  useEffect(() => {
    if (collection) {
      tracker.general.contentDetailPageViewed({
        content_id: collection.id,
        content_title: collection.title || '',
        content_type: collection.__typename,
        genre: collection.parent?.name || '',
        source: 'browse',
        countryOfProduction: collection.countryOfProduction,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      appSlice.actions.setModalHeader(
        <CustomModalHeader onGoBack={navigation.goBack} isCast={true} />,
      ),
    );
    return () => {
      dispatch(appSlice.actions.setModalHeader(null));
    };
  }, []);

  const handleWatch = (item?: IVodItemFragmentFragment) => () => {
    tracker.general.cTAClicked({
      type: 'banner',
      name: t('Watch') || '',
      section: 'watch',
      page: 'Collection page',
      blockType: 'highlight',
      blockName: item?.title || '',
    });
    if (item) {
      let time = findCurrentTime(item.id);
      //   if (castDevice) {
      //     handleSetContentCast({
      //       uri: async () => {
      //         const res = await store.dispatch(
      //           traceApi.endpoints.getVimeoVideo.initiate(item.liveEventID + ''),
      //         );
      //         if (res && res.data && res.data.hls) {
      //           return res.data.hls;
      //         }
      //         return '';
      //       },
      //     });
      //   } else {
      const canSee = canSeeContent(item.accessType);
      const canPreview = getPremiumPreviewSettings(
        item.paywall as IPaywallFragmentFragment,
      )?.canPreview;
      if (canSee || canPreview) {
        let time = findCurrentTime(item.id);
        navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
          state: {
            params: {
              type: 'player',
              source: 'vimeo',
              liveEventId: item.liveEventID ? item.liveEventID + '' : '',
              title: item.name || '',
              url: item.url || '',
              videoId: item.liveEventID,
              videoUrl: item.vimeoHlsUrl,
              time,
              paywallData: {
                accessType: item.accessType,
                paywall: item.paywall,
              },
              id: item.id || '',
              list:
                collection && collection.id
                  ? {
                      id: collection.id,
                    }
                  : undefined,
              previousScreen: 'content',
            },
          },
        });
      } else {
        navigation.navigate(AppRoutesEnum.PAYWALL, {
          state: {
            params: {
              id: 'default',
              headerTitle: title || '',
              paywall: item.paywall,
            },
          },
        });
      }
    }
    // }
  };

  const handleWatchTrailer = () => {
    if (collection && collection.trailer) {
      let time = findCurrentTime(collection.trailer + '');
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            type: 'player',
            source: 'vimeo',
            title: collection.title || '',
            url: collection.url || '',
            videoId: collection.trailer + '',
            liveEventId: collection.trailer,
            paywallData: {},
            id: collection.id,
            time,
            previousScreen: 'content',
          },
        },
      });
    }
  };

  return (
    <div id="vod-collection-page">
      {loading ? (
        <SingleContentLoader />
      ) : collection ? (
        <>
          <SingleHeader
            variant="vod"
            text={description || ''}
            listCategory={collectionPublic as string[]}
            image={
              (collection &&
                collection.thumbnail &&
                collection.thumbnail.url && {
                  uri: collection.thumbnail.url,
                }) ||
              undefined
            }
            seeMoreLabel={t('See more') + ''}
            seeLessLabel={t('See less') + ''}
            trailerLabel={t('Trailer') + ''}
            favoriLabel={t('Favoris') + ''}
            shareLabel={t('Share') + ''}
            title={title || ''}
            buttonTitle={t('Watch') || ''}
            onPressButton={handleWatch(lastWatchedItem)}
            onClickPlay={
              collection && collection.trailer ? handleWatchTrailer : undefined
            }
            length={
              (lastWatchedItem &&
                parseDuration(findDuration(lastWatchedItem.id), 'second')) ||
              undefined
            }
            episode={
              t('Episode {{number}}', {
                number: lastWatchedItemNumber + '',
              }) + ''
            }
            isSerie={true}
            size="large"
            progress={
              lastWatchedItem
                ? Math.min(findProgression(lastWatchedItem.id), 1)
                : 0
            }
            onClickShare={() => {
              if (collection?.url) {
                shareContent.share({
                  __typename: collection.__typename,
                  url: collection.url,
                  message: collection.title,
                  id: collection.id,
                  category: collection.parent?.name,
                  cover:
                    collection.thumbnail && collection.thumbnail.url
                      ? collection.thumbnail.url
                      : '',
                  description:
                    collection.description || collection.shortDescription || '',
                  title: collection.title,
                  countryOfProduction: collection.countryOfProduction,
                });
                const link = getLinkToShare({
                  param: {
                    __typename: collection.__typename,
                    id: collection.id,
                    url: collection.url,
                  } as any,
                });
                setLinkToShare(link);
                setIsOpenDialogShare(!isOpenDialogShare);
              }
            }}
            isLiked={isBookmarked(collection?.id || '')}
            // id={lastWatchedItem?.id ?? ''}
            // liveEventID={lastWatchedItem?.liveEventID}
            onClickFav={(value: boolean) => {
              if (collection) {
                collection.id &&
                  collection.url &&
                  updateBookMark(collection.id, collection.url, value, {
                    id: collection.id,
                    url: collection.url,
                    __typename: collection.__typename,
                    name: collection.title,
                    categoryName: collection.parent?.name,
                    raw: collection,
                  });
              }
            }}
          />

          <View
            style={{
              marginTop: Metrics.verticalScale(isMobile ? 30 : 57),
              marginBottom: Metrics.verticalScale(15),
              paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
            }}>
            <AppDropDownMenuModal
              title={
                seasonList && seasonList.length > 0
                  ? selectedSeason?.title ?? ''
                  : t('Season {{number}}', {number: 1}) || ''
              }
              containerWidth={isMobile ? '100vw' : calculateWidth()}
              items={seasonList.map((item, index) => {
                return {
                  title: item.title,
                  onPress: () => {
                    setCurrentSeason(item.num);
                  },
                };
              })}
            />
          </View>

          {episodes.map((item, index) => {
            return (
              <View
                key={index}
                style={{
                  marginVertical: Metrics.verticalScale(14),
                  marginHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
                  marginTop: index === 0 ? 0 : undefined,
                }}>
                <SharedCard
                  contentType="VodCollection"
                  premiumText={t('premium') as string}
                  isFreemium={canSeeContent(item.accessType)}
                  title={item.title || ''}
                  length={parseDuration(
                    findDuration(item.id) || item.length || 0,
                  )}
                  onPressPlayIcon={handleWatch(item)}
                  cover={{
                    uri: item.thumbnail?.url,
                  }}
                  description={item.description || ''}
                  onPressShareIcon={() => {
                    if (item?.url) {
                      shareContent.share({
                        __typename: item.__typename,
                        url: item.url,
                        message: item.title,
                        id: item.id,
                        category: item.parent?.name,
                        cover:
                          item.thumbnail && item.thumbnail.url
                            ? item.thumbnail.url
                            : '',
                        description:
                          item.description || item.shortDescription || '',
                        countryOfProduction: item.productionCountry,
                      });
                      const link = getLinkToShare({
                        param: {
                          type: 'player',
                          source: 'vimeo',
                          liveEventId: item.liveEventID
                            ? item.liveEventID + ''
                            : '',
                          title: item.name || '',
                          url: item.url || '',
                          videoId: item.id,
                          videoUrl: item.vimeoHlsUrl,
                          id: item.id || '',
                          list:
                            collection && collection.id
                              ? {
                                  id: collection.id,
                                }
                              : undefined,
                          previousScreen: 'content',
                          paywallData: {
                            accessType: item.accessType,
                            paywall: item.paywall,
                          },
                        } as any,
                        path: AppRoutesEnum.VIDEO_PLAYER,
                      });
                      setLinkToShare(link);
                      setIsOpenDialogShare(!isOpenDialogShare);
                    }
                  }}
                  isLiked={isBookmarked(item?.id || '')}
                  progress={Math.min(findProgression(item.id), 1)}
                  onPressFavIcon={(value: boolean) => {
                    if (item) {
                      item.id &&
                        item.url &&
                        updateBookMark(item.id, item.url, value, {
                          id: item.id,
                          url: item.url,
                          __typename: item.__typename,
                          name: item.title,
                          categoryName: item.parent?.name,
                          raw: item,
                        });
                    }
                  }}
                  seeMoreLabel={t('See more') + ''}
                  seeLessLabel={t('See less') + ''}
                />
              </View>
            );
          })}
          {/* Related item */}
          <View
            style={{
              marginTop: isMobile ? 26 : 56,
              width: isMobile ? '100%' : calculateWidth(),
            }}>
            {collection && <RelatedCategory id={collection.id} />}
          </View>
          <ShareDialog
            isOpen={isOpenDialogShare}
            onClose={() => setIsOpenDialogShare(false)}
            link={linkToShare}
          />
        </>
      ) : (
        <ContentEmpty title={t('Content not available in your region')} />
      )}
    </div>
  );
};

export default VodCollectionPage;

import {StyleSheet, View, StyleProp, ViewStyle} from 'react-native';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import PayWallChoosePlan, {
  IItemPlan,
  PayWallChoosePlanProps,
} from 'design-system/src/Widget/PayWall/PayWallChoosePlan/PayWallChoosePlan';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Metrics from 'design-system/src/Theme/Metrics';
import {useInAppPurchase} from '~Hooks/useInAppPurchase';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Typography from 'design-system/src/Components/Typography/Typography';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import queryString from 'query-string';
import useMember from '@src/Store/selectors/useMember';
import {usePromoCode} from '@src/Navigation/PromoCodePage/usePromocode';
import {IPaywallOrientation} from '../PaywallSection.type';
interface IPaywallConfirmPageProps {
  variant?: PayWallChoosePlanProps['variant'];
  listFilter?: (item: IItemPlan) => boolean;
  productIdOrder?: string[];
  onMore?: boolean;
  children?: (props: {
    allSubscription: IItemPlan[];
    loading: boolean;
  }) => ReactNode;
  needStore?: boolean;
  horizontal?: boolean;
  contentWrapperStyle?: StyleProp<ViewStyle>;
  orientation?: IPaywallOrientation;
}

const PaywallConfirmPage = (props: IPaywallConfirmPageProps) => {
  const {
    variant,
    listFilter,
    children,
    onMore,
    needStore,
    horizontal,
    contentWrapperStyle,
    productIdOrder,
    orientation,
  } = props;
  const {
    handleBuySubscription,
    buyLoading,
    loading: loadingSub,
    allSubscription,
  } = useInAppPurchase(needStore);
  const {handleGetPromocode, loadingPromocode} = usePromoCode();
  const [selectedPlan, setSelectedPlan] = useState<IItemPlan>();
  const {t} = useTranslation('subscription');
  const navigation = useAppNavigation();
  const member = useMember();

  const loading: boolean = loadingPromocode || loadingSub;

  useEffect(() => {
    handleGetPromocode();
  }, []);

  const handleConfirm = useCallback(() => {
    const subLink =
      selectedPlan?.subscriptionLink && member
        ? queryString.stringifyUrl({
            url: selectedPlan?.subscriptionLink,
            query: {client_reference_id: member?._id},
          })
        : '';
    if (subLink) {
      window.open(subLink, '_self');
    }
  }, [handleBuySubscription]);

  const handleOnItemClick = useCallback((plan: IItemPlan) => {
    setSelectedPlan(plan);
  }, []);

  if (children) {
    return <>{children({allSubscription, loading})}</>;
  }

  const items = (
    listFilter ? allSubscription.filter(listFilter) : allSubscription
  )
    .map(item => ({
      ...item,
      price: item.price ? item.price : t('free'),
    }))
    .sort((a, b) => {
      const indexA = productIdOrder?.indexOf(a.planId) ?? -1;
      const indexB = productIdOrder?.indexOf(b.planId) ?? -1;
      return indexA - indexB;
    });
  return (
    <View style={{flex: 1}}>
      <View style={{paddingTop: Metrics.verticalScale(31)}}>
        {loading ? (
          <View style={styles.center}>
            <CustomActivityIndicator />
          </View>
        ) : (
          <View style={[styles.container, {}]}>
            {allSubscription.length > 0 ? (
              <PayWallChoosePlan
                orientation={orientation}
                loading={buyLoading}
                contentWrapperStyle={contentWrapperStyle}
                horizontal={horizontal}
                items={items}
                title={t('Choose your plan')}
                freeButtonTitle={t('By default')}
                primaryButtonTitle={t('Confirmed')}
                secondaryButtonTitle={t('Cancel')}
                thirdButtonTitle={t('See details')}
                onCancel={() =>
                  navigation.navigate(AppRoutesEnum.PROFILE_SUBSCRIPTION, {
                    replace: true,
                    state: {
                      headerTitle: '',
                      params: {
                        id: 'default',
                      },
                    },
                  })
                }
                onMore={
                  onMore
                    ? () => {
                        navigation.navigate(AppRoutesEnum.PAYWALL, {
                          state: {
                            headerTitle: '',
                            params: {
                              id: 'default',
                            },
                          },
                        });
                      }
                    : undefined
                }
                variant={variant}
                onItemPress={handleOnItemClick}
                onConfirm={handleConfirm}
              />
            ) : (
              <View style={styles.center}>
                <Typography
                  children={t('No avalaible subscription')}
                  variant="h3"
                />
              </View>
            )}
          </View>
        )}
      </View>
      {/* {buyLoading && (
        <View style={styles.showLoading}>
          <CustomActivityIndicator />
        </View>
      )} */}
    </View>
  );
};

export default PaywallConfirmPage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  showLoading: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  center: {flex: 1, justifyContent: 'center', alignItems: 'center'},
});

/* eslint-disable react-native/no-inline-styles */
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Pressable, View} from 'react-native';
import {
  IChoiceNuggetFragmentFragment,
  IGapfillNuggetFragmentFragment,
  ISummaryNuggetFragmentFragment,
  useCourseExamByIdQuery,
  useGetExamOverviewQuery,
  useJobTrainingByCourseQuery,
} from '~Api/Graphql/gql/types.generated';
// import {useSnackbarNotification} from '~Store/Slices/SnackbarSlice/SnackBar.selector';
import TestScreenContainer from '../TestScreen/TestScreenContainer';
import ExamNugget from './ExamNugget';
import Typography from 'design-system/src/Components/Typography/Typography';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CoursePage, {
  ICoursePageProps,
} from '../UnitScreen/UnitPages/CoursePage/CoursePage';
import {useCourseTestProgression} from '~Store/Slices/CourseUnitSlice/CourseUnit.selector';
import DateFactory from '~tools/DateFactory';
import dayjs from 'dayjs';
import courseUnitSlice, {
  useSelectExamInfo,
} from '~Store/Slices/CourseUnitSlice/CourseUnit.slice';
import Metrics from 'design-system/src/Theme/Metrics';
import {
  useLazyComputeExamProgressQuery,
  useLazyGetAllUnitProgressionQuery,
  useLazyGetMemberCourseTestProgressionByIdQuery,
} from '~Api/TraceApi/traceApi';
import ExamResult from './ExamResult';
import Button from 'design-system/src/Components/Button/Button';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {formatDuration} from '~Hooks/useStringExtension';
import utc from 'dayjs/plugin/utc';
import {useMemberCourseStatus} from '~Hooks/useMemberCourseStatus';
import {useMemberObtainedJob} from '~Hooks/useMemberObtainedJob';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import useAppNavigation from '@src/utils/useAppNavigation';
import useParams from '@src/Hooks/useParams';
import useMember from '@src/Store/selectors/useMember';
import {useAppDispatch} from '@src/Store/hooks';
import {AppRoutesEnum} from '@src/Constant/routes';
import appSlice from '@src/Store/Slices/AppSlice';
import CustomModalHeader from '@src/components/general/CustomModal/CustomModalHeader';
import {toast} from 'react-toastify';
import useExamFetch from './useExamFetch';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';
// import {IExamResult, IExamResultItem} from '@src/Api/TraceApi/interface/exam';
dayjs.extend(utc);

const ExamScreen = () => {
  const params = useParams();
  const navigation = useAppNavigation();
  const {userStartExam, endUrl1, endUrl2} = params || {};
  const endUrl = `/${endUrl1}/${endUrl2}/`.replace(/\/\//g, '/');

  const {exam, loading, examId, courseId} = useExamFetch(endUrl);

  const [step, setStep] = useState(0);

  const {t} = useTranslation(['learn']);
  const currentLanguage = useCurrentLanguage();
  const member = useMember();
  // const snackbar = useSnackbarNotification();

  useEffect(() => {
    navigation.setOptions(undefined);
  }, []);

  const courseTestProgression = useCourseTestProgression(exam?.id || '');
  const [
    computeExamProgress,
    {
      isLoading: computeLoading,
      data: resultData,
      // error: computeError
    },
  ] = useLazyComputeExamProgressQuery();
  const isUserStartedExam = useRef<boolean>(userStartExam || false);
  const dispatch = useAppDispatch();
  const certificateResponse = useJobTrainingByCourseQuery({
    variables: {
      id: courseId,
    },
    skip: !courseId,
  });
  const [
    fetchCourseTestProgression,
    {
      data: progress,
      isLoading: progressIsLoading,
      isFetching: progressFetching,
    },
  ] = useLazyGetMemberCourseTestProgressionByIdQuery();
  const progressLoading = progressIsLoading || progressFetching;
  const isLoading = computeLoading;
  const [
    fetchAllUnitProgression,
    {isLoading: unitLoading, data: allUnitProgress},
  ] = useLazyGetAllUnitProgressionQuery();

  const {data: overViewData, loading: overViewLoading} =
    useGetExamOverviewQuery();
  // const {canPlay} = useListenPlayer();

  useEffect(() => {
    // TODO: pause listen player
    // if (canPlay) {
    //   dispatch(listenPlayerSlice.actions.setCanPlay(false));
    // }

    return () => {
      // if (!canPlay) {
      //   dispatch(listenPlayerSlice.actions.setCanPlay(true));
      // }
    };
  }, []);

  useEffect(() => {
    if (examId && member) {
      fetchAllUnitProgression();
      fetchCourseTestProgression({
        id: examId,
      });
    }
  }, [examId, member]);

  const {
    courseTitle,
    property,
    isUnitsFinished,
  }: {
    property?: ILearnPropertyName;
    isUnitsFinished?: boolean;
    courseTitle?: string;
  } = useMemo(() => {
    let _property: ILearnPropertyName | undefined;
    let _isUnitsFinished: boolean | undefined;
    let _courseTitle: string | undefined;
    if (
      exam &&
      exam.parent &&
      exam.parent.__typename === 'Course' &&
      exam.parent.children &&
      exam.parent.children.items
    ) {
      const course = exam.parent;
      const {interests} = course;
      let certificateName: string | undefined;
      let certificateId: string | undefined;
      const {data: certificate} = certificateResponse;
      if (
        certificate &&
        certificate.allJobTraining &&
        certificate.allJobTraining.items &&
        certificate.allJobTraining.items[0] &&
        certificate.allJobTraining.items[0].__typename === 'JobTraining'
      ) {
        certificateName = certificate.allJobTraining.items[0].name;
        certificateId = certificate.allJobTraining.items[0].id;
      }
      _courseTitle = course.title;
      let interest: string[] = [];
      interests?.forEach(_interest => {
        if (
          _interest &&
          _interest.__typename === 'ContentTag' &&
          _interest.name
        ) {
          interest.push(_interest.name);
        }
      });
      _property = {
        courseName: course.name,
        courseId: course.id,
        partnerName:
          course.linkedPartners &&
          course.linkedPartners[0] &&
          course.linkedPartners[0].__typename === 'Partner'
            ? course.linkedPartners[0].partnerName
            : '',
        certificateName,
        certificateId,
        interestName: interest.join(','),
      };
      if (allUnitProgress) {
        const units = exam.parent.children.items.filter(
          item => item?.__typename === 'CourseUnit',
        );
        if (units.length) {
          _isUnitsFinished = units.every(item => {
            const unit = allUnitProgress.find(
              unitProgression =>
                unitProgression &&
                unitProgression.finished &&
                item &&
                item.__typename === 'CourseUnit' &&
                unitProgression.unitId === item.id,
            );
            return unit !== undefined;
          });
        }
      }
    }
    return {
      property: _property,
      isUnitsFinished: _isUnitsFinished,
      courseTitle: _courseTitle,
    };
  }, [allUnitProgress, exam, certificateResponse]);

  useEffect(() => {
    if (examId) {
      dispatch(
        courseUnitSlice.actions.resetCourseTestNuggetAnswer({
          testId: examId,
        }),
      );
    }
  }, [examId]);

  const {handleFetchObtainedJob} = useMemberObtainedJob();
  const {fetchCourseStatus} = useMemberCourseStatus();
  const examInfo = useSelectExamInfo(examId || '');

  const {
    examData,
    totalStep,
    nextLabel,
    attemptDelay,
    allowedAttempts,
    gradingPolicy,
    limitedAttempts,
  }: {
    examData?:
      | IChoiceNuggetFragmentFragment[]
      | IGapfillNuggetFragmentFragment[]
      | ISummaryNuggetFragmentFragment[];
    totalStep: number;
    nextLabel: string;
    attemptDelay?: number;
    allowedAttempts?: number;
    gradingPolicy?: number;
    limitedAttempts?: boolean;
  } = useMemo(() => {
    const _totalStep =
      exam && exam.examNuggets ? exam.examNuggets.length + 1 : 1;
    const _nextLabel: string =
      step <= 0
        ? t('begin test')
        : step < _totalStep - 1
          ? t('Continue')
          : t('Finish');
    if (exam) {
      return {
        examData: exam.examNuggets as
          | IChoiceNuggetFragmentFragment[]
          | IGapfillNuggetFragmentFragment[]
          | ISummaryNuggetFragmentFragment[],
        totalStep: _totalStep,
        nextLabel: _nextLabel,
        attemptDelay: exam.attemptDelay,
        allowedAttempts: exam.allowedAttempts,
        gradingPolicy: exam.gradingPolicy,
        limitedAttempts: exam.limitedAttempts,
      };
    }
    return {totalStep: _totalStep, nextLabel: _nextLabel};
  }, [exam, step]);

  const handleNext = useCallback(async () => {
    isUserStartedExam.current = true;
    if (step < totalStep - 1) {
      if (examInfo && examId) {
        dispatch(courseUnitSlice.actions.removeExamInfo({examId}));
      }
      setStep(step + 1);
    } else {
      setStep(step + 1);
      dispatch(
        courseUnitSlice.actions.saveExamInfo({
          examId: examId || '',
          lastAttempt: new DateFactory(Date.now()).utcToISOFormat().toString(),
          attempt: (progress?.attempt || 0) + 1,
        }),
      );

      const response = await computeExamProgress({
        testId: examId || '',
        data: courseTestProgression,
        culture: currentLanguage,
      });
      if (response.error) {
        toast.error(t('Error on saving'));
      } else {
        fetchCourseStatus();
        handleFetchObtainedJob();
        toast.success(t('Congratulation! You have completed the exam'));
      }
      setStep(step + 1);
      if (response.error) {
        dispatch(
          courseUnitSlice.actions.removeExamInfo({examId: examId || ''}),
        );
      }
      dispatch(
        courseUnitSlice.actions.resetCourseTestNuggetAnswer({
          testId: examId || '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, totalStep, examId, examInfo, progress, currentLanguage]);

  const postLoadingRef = useRef<boolean>(isLoading);

  const handleClose = useCallback(async () => {
    if (!postLoadingRef.current) {
      postLoadingRef.current = true;
      navigation.goBack();
      setTimeout(() => (postLoadingRef.current = false), 1500);
    }
  }, [navigation]);

  useEffect(() => {
    const handleGoBack = async () => {
      if (step === 0) {
        handleClose();
      } else {
        if (!postLoadingRef.current) {
          // move next step
          // navigation.setParams({
          //   id: id,
          //   step: Math.max(step - 1, 0),
          //   userStartExam: isUserStartedExam.current,
          // });
          setStep(Math.max(step - 1, 0));
        }
      }
    };

    dispatch(
      appSlice.actions.setModalHeader(
        <CustomModalHeader
          title={
            step === totalStep ? (
              <Typography variant="h4" color="white">
                {t('your marks')}
              </Typography>
            ) : step !== 0 ? (
              <Typography variant="h4" color="white">
                {t('Step')}{' '}
                <Typography variant="h4" color="primary">
                  {t('{{current}} of {{total}}', {
                    current: step,
                    total: totalStep - 1,
                  })}
                </Typography>
              </Typography>
            ) : (
              <Typography variant="h4" color="white">
                {t('Test').toUpperCase()}
              </Typography>
            )
          }
          onGoBack={step !== totalStep ? handleGoBack : undefined}
          onClose={() => navigation.goBack()}
        />,
      ),
    );
    return () => {
      dispatch(appSlice.actions.setModalHeader(null));
    };
  }, [step, totalStep]);

  const {
    examOverview,
    positiveResult,
    negativeResult,
  }: {
    examOverview: ICoursePageProps['overview'];
    positiveResult?: {
      title: string;
      body: string;
    };
    negativeResult?: {
      title: string;
      body: string;
    };
  } = useMemo(() => {
    if (
      overViewData &&
      overViewData.allLearnMenu &&
      overViewData.allLearnMenu.items &&
      overViewData.allLearnMenu.items[0]
    ) {
      const {
        pageContent,
        subtitle,
        cover,
        positiveResult: positiveResultData,
        negativeResult: negativeResultData,
      } = overViewData.allLearnMenu.items[0];
      return {
        examOverview: {
          pageContent,
          subtitle,
          cover:
            cover &&
            cover[0] &&
            cover[0].content &&
            cover[0].content.__typename === 'HeroVideoCover'
              ? cover[0].content.image?.url
              : '',
        },
        positiveResult:
          positiveResultData &&
          positiveResultData[0] &&
          positiveResultData[0].content &&
          positiveResultData[0].content.__typename === 'Wysiwyg'
            ? {
                title: positiveResultData[0].content.title || '',
                body: positiveResultData[0].content.body || '',
              }
            : undefined,
        negativeResult:
          negativeResultData &&
          negativeResultData[0] &&
          negativeResultData[0].content &&
          negativeResultData[0].content.__typename === 'Wysiwyg'
            ? {
                title: negativeResultData[0].content.title || '',
                body: negativeResultData[0].content.body || '',
              }
            : undefined,
      };
    }
    return {
      examOverview: {
        pageContent: undefined,
        subtitle: undefined,
        cover: undefined,
      },
    };
  }, [overViewData]);

  if (
    loading ||
    progressLoading ||
    unitLoading ||
    overViewLoading ||
    computeLoading
  ) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CustomActivityIndicator />
      </View>
    );
  }

  // TODO: use this to display current progress result
  // const progressResult: IExamResult = {
  //   totalQuestion: progress?.result?.totalQuestion || 0,
  //   correct: progress?.result?.correct || 0,
  //   result: progress?.result?.percent || 0,
  //   stat: (progress?.stat || []) as IExamResultItem[],
  //   success: progress?.success || false,
  // };

  if (progress) {
    let lastAttempt = progress?.lastAttempt;
    let userAttempt = progress?.attempt || 0;
    const _currentAttempt = examInfo?.attempt || userAttempt || 0;
    if (
      limitedAttempts &&
      allowedAttempts &&
      _currentAttempt >= allowedAttempts
    ) {
      return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Typography>
            {t('You have exceeded the maximum number of attempts')}
          </Typography>
          <DebugUmbracoId umbracoId={examId} position="page" />
        </View>
      );
    }

    const currentAttemptDelay = attemptDelay || 0;
    const _currentLastAttempt = examInfo?.lastAttempt || lastAttempt;
    if (
      _currentLastAttempt &&
      dayjs().utc().diff(_currentLastAttempt, 'second') < currentAttemptDelay
    ) {
      const time = currentAttemptDelay - dayjs().diff(lastAttempt, 'second');
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: Metrics.horizontalScale(20),
            paddingTop: Metrics.verticalScale(80),
          }}>
          <Typography
            style={{
              textAlign: 'center',
            }}>
            {t('wait for {{delay}}', {
              delay: formatDuration(time, time < 3600),
            })}
          </Typography>
          <DebugUmbracoId umbracoId={examId} position="page" />
        </View>
      );
    }
  }
  if (resultData) {
    return (
      <TestScreenContainer
        style={{
          height: 'unset',
        }}
        buttonTitle={''}
        customFooter={() => (
          <View
            style={{
              paddingHorizontal: Metrics.horizontalScale(24),
              paddingBottom: Metrics.verticalScale(40),
            }}>
            <Button
              title={t('return to course')}
              variant="contained"
              size="medium"
              style={{
                width: '100%',
              }}
              onPress={() => navigation.goBack()}
            />
            <View
              style={{
                marginTop: Metrics.verticalScale(26),
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              <Pressable
                onPress={() => {
                  navigation.navigate(AppRoutesEnum.HOME_LEARN, {
                    replace: true,
                  });
                }}>
                <Typography
                  style={{textAlign: 'center'}}
                  variant="button3"
                  color="orange">
                  {t('Return home')}
                </Typography>
              </Pressable>
            </View>
          </View>
        )}>
        <ExamResult
          data={resultData}
          gradingPolicy={gradingPolicy}
          positiveResult={positiveResult}
          negativeResult={negativeResult}
          delay={attemptDelay}
          onCourseFinished={() => {
            if (property && isUnitsFinished) {
              tracker.learn.courseFinished(property);
            }
          }}
        />
        <DebugUmbracoId umbracoId={examId} position="page" />
      </TestScreenContainer>
    );
  }

  if (step === 0 && examOverview) {
    return (
      <TestScreenContainer
        buttonTitle={nextLabel}
        onPressButton={handleNext}
        noPadding={true}
        loading={loading || isLoading}>
        <CoursePage
          overview={examOverview}
          hasPadding={true}
          courseTitle={courseTitle}
          isExamenOverview={true}
        />
        <DebugUmbracoId umbracoId={examId} position="page" />
      </TestScreenContainer>
    );
  }
  if (examData && examData.length && examData[step - 1]) {
    const stepContent = examData[step - 1];
    // const nuggetId = examData[step - 1].id;
    // let disabled: boolean = true;
    // if (nuggetId && courseTestProgression[nuggetId]) {
    //   disabled = false;
    // }
    return (
      <TestScreenContainer
        buttonTitle={nextLabel}
        onPressButton={handleNext}
        buttonDisabled={false}
        loading={isLoading}>
        <ExamNugget content={stepContent} showResult={false} testId={examId} />
        <DebugUmbracoId umbracoId={examId} position="page" />
      </TestScreenContainer>
    );
  }

  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      {!loading ? (
        <ContentEmpty
          title={t('exam page not found')}
          buttonTitle={t('Return home') as string}
          onPress={() => navigation.navigate(AppRoutesEnum.HOME_LEARN)}
        />
      ) : (
        <CustomActivityIndicator />
      )}
    </View>
  );
};

export default ExamScreen;

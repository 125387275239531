exports.components = {
  "component---src-page-templates-award-artist-voting-page-template-tsx": () => import("./../../../src/page-templates/AwardArtistVotingPageTemplate.tsx" /* webpackChunkName: "component---src-page-templates-award-artist-voting-page-template-tsx" */),
  "component---src-page-templates-award-category-page-template-tsx": () => import("./../../../src/page-templates/AwardCategoryPageTemplate.tsx" /* webpackChunkName: "component---src-page-templates-award-category-page-template-tsx" */),
  "component---src-page-templates-helpdesk-categ-template-tsx": () => import("./../../../src/page-templates/HelpdeskCategTemplate.tsx" /* webpackChunkName: "component---src-page-templates-helpdesk-categ-template-tsx" */),
  "component---src-page-templates-landing-page-template-tsx": () => import("./../../../src/page-templates/LandingPageTemplate.tsx" /* webpackChunkName: "component---src-page-templates-landing-page-template-tsx" */),
  "component---src-page-templates-text-page-template-tsx": () => import("./../../../src/page-templates/TextPageTemplate.tsx" /* webpackChunkName: "component---src-page-templates-text-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-awards-category-[id]-tsx": () => import("./../../../src/pages/awards/category/[id].tsx" /* webpackChunkName: "component---src-pages-awards-category-[id]-tsx" */),
  "component---src-pages-awards-index-tsx": () => import("./../../../src/pages/awards/index.tsx" /* webpackChunkName: "component---src-pages-awards-index-tsx" */),
  "component---src-pages-awards-voting-[id]-tsx": () => import("./../../../src/pages/awards/voting/[id].tsx" /* webpackChunkName: "component---src-pages-awards-voting-[id]-tsx" */),
  "component---src-pages-forms-[id]-tsx": () => import("./../../../src/pages/forms/[id].tsx" /* webpackChunkName: "component---src-pages-forms-[id]-tsx" */),
  "component---src-pages-p-tsx": () => import("./../../../src/pages/p/[...].tsx" /* webpackChunkName: "component---src-pages-p-tsx" */),
  "component---src-pages-subscription-tsx": () => import("./../../../src/pages/subscription.tsx" /* webpackChunkName: "component---src-pages-subscription-tsx" */),
  "component---src-pages-thank-you-[id]-tsx": () => import("./../../../src/pages/thank-you/[id].tsx" /* webpackChunkName: "component---src-pages-thank-you-[id]-tsx" */),
  "component---src-pages-tv-auth-tsx": () => import("./../../../src/pages/tv-auth.tsx" /* webpackChunkName: "component---src-pages-tv-auth-tsx" */)
}


import React from 'react';
import {View, StyleProp, ViewStyle, StyleSheet} from 'react-native';
import defaultTheme from '../../../../Theme/defaultTheme';
import Metrics from '../../../../Theme/Metrics';
import Typography from '../../../Typography/Typography';
import {ITypographyProps} from '../../../Typography/Typography.props';
import {subscriptionCardContentStyles} from './SubscriptionCardContent.styles';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';

export type GenericPremiumCardProps = {
  title: string;
  description: string;
  leadingCard: React.ReactNode;
  trailing?: React.ReactNode;
  headerExtra?: React.ReactNode;
  descriptionColor?: string;
  descTypo?: ITypographyProps['variant'];
  subTitle?: string;
  descriptionLine?: number;
  headerStyle?: StyleProp<ViewStyle>;
  variant?: 'default' | 'center';
  trailingStyle?: StyleProp<ViewStyle>;
};

const SubscriptionCardContent = (props: GenericPremiumCardProps) => {
  const {
    title,
    description,
    descriptionColor = defaultTheme.primaryInfo,
    leadingCard,
    trailing,
    headerExtra,
    descTypo = 'body2',
    subTitle,
    descriptionLine,
    headerStyle,
    variant = 'default',
    trailingStyle,
  } = props;
  const {isMobile} = useBreakpoint();

  const isCenterVariant = variant === 'center';
  const textAlign = isCenterVariant ? 'center' : 'left';
  return (
    <View
      testID="subscription-card-content"
      style={[
        subscriptionCardContentStyles.body,
        isCenterVariant && {
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        },
      ]}>
      {leadingCard}
      <View
        style={{
          ...subscriptionCardContentStyles.aside,
          ...(isCenterVariant && {
            alignItems: 'center',
            marginTop: Metrics.verticalScale(32),
            marginLeft: 0,
          }),
        }}>
        <View
          style={{
            paddingRight: Metrics.horizontalScale(10),
          }}>
          <View
            style={[{flexDirection: 'row', alignItems: 'center'}, headerStyle]}>
            <Typography
              variant={isMobile ? 'extra' : 'h1'}
              color={'white'}
              style={StyleSheet.flatten([
                subscriptionCardContentStyles.title,
                {
                  textAlign: textAlign,
                },
              ])}>
              {title}
            </Typography>
            {subTitle !== undefined && (
              <Typography
                variant="ui3"
                color="gray"
                style={StyleSheet.flatten([
                  {
                    marginLeft: Metrics.horizontalScale(8),
                    flexShrink: 1,
                    textAlign: textAlign,
                  },
                ])}>
                {subTitle}
              </Typography>
            )}
            {headerExtra}
          </View>
          <Typography
            numberOfLines={descriptionLine}
            variant={descTypo}
            color={'white'}
            style={StyleSheet.flatten([
              subscriptionCardContentStyles.description,
              {
                marginTop: isCenterVariant
                  ? Metrics.verticalScale(11)
                  : Metrics.verticalScale(4),
                textAlign: textAlign,
              },
            ])}>
            {description}
          </Typography>
        </View>
        <View
          style={[
            {height: '100%', marginTop: Metrics.verticalScale(8)},
            trailingStyle,
          ]}>
          {trailing}
        </View>
      </View>
    </View>
  );
};

export default SubscriptionCardContent;

/* eslint-disable react-hooks/exhaustive-deps */

import Typography from 'design-system/src/Components/Typography/Typography';
import React, {ReactNode, useCallback, Suspense} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Animated, StyleProp, View, ViewStyle} from 'react-native';
import {
  IPaywallFragmentFragment,
  IQuizzSurveyCategoryFragmentFragment,
  ISectionPageContentFragmentFragment,
  Maybe,
} from '~Api/Graphql/gql/types.generated';
import {RouteTypename} from '~Navigation/type';

import {
  ContentSource,
  EContextualContentSliderType,
} from '~Umbraco/Components/ContextualContentSlider/ContextualContentSlider';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';
import {
  HeroSliderData,
  mapHeroSlide,
  mapLearnHeroSlide,
} from '~Umbraco/Utils/content/mapHeroSlide';
import {mapNewsCategItems} from '~Umbraco/Utils/content/mapNewsCategItems';
import {mapCategoryChildrenItems} from '~Umbraco/Utils/content/mapCategoryChildrenItems';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {HighlightedCarouselProps} from 'design-system/src/Components/Card/HighlightedCarousel/HighlightedCarousel.web';
import Metrics from 'design-system/src/Theme/Metrics';
import {useDispatch} from 'react-redux';
import {useFindProgression} from '@src/Hooks/useFindProgression';
import {useLiveEventProgression} from '@src/Hooks/useLiveEventProgression';
import usePaywal from '@src/Hooks/usePaywal';
import {IContentSliderItem} from '../Components/ContentSlider/ContentSlider';
import {mapContentItems} from '../Utils/mapContentItems';
import mapMultiCategoryItems from '../Utils/mapMultiCategoryItems';
import {mapLiveTVPage} from '../Utils/pages/mapLiveTVPage';
import {mapLiveEventsPage} from '../Utils/pages/mapLiveEventsPage';

import {IItemAppCarouselProps} from '@src/App/components/Common/AppCarousel/ItemAppCarousel/ItemAppCarousel';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {
  ICategorySliderItem,
  ICategorySliderProps,
} from '../Components/CategorySlider/CategorySlider';
import {useMapCourseCateg} from '../Utils/content/mapCourseCategItems';
import CourseSlider, {
  ICourseSliderItem,
} from '../Components/CourseSlider/CourseSlider';
import {ILearnHomeCardProps} from 'design-system/src/Components/Card/LearnCard/LearnHomeCard/LearnHomeCard';
import {mapPartnerSliderItems} from '../Utils/content/mapPartnerSliderItems';
import PartnerSlider, {
  IPartenerSliderItem,
} from '../Components/PartnerSlider/PartnerSlider';
import {useMapQuizzSurvey} from '../Utils/content/mapQuizzSurvey';
import PushWrapper from '@src/App/components/Common/PushWrapper/PushWrapper';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';

const AppCarousel = React.lazy(
  () => import('@src/App/components/Common/AppCarousel/AppCarousel'),
);

const ContextualContentSlider = React.lazy(
  () =>
    import(
      '~Umbraco/Components/ContextualContentSlider/ContextualContentSlider'
    ),
);

const HighlightedCarousel = React.lazy(
  () =>
    import(
      'design-system/src/Components/Card/HighlightedCarousel/HighlightedCarousel.web'
    ),
);

const CategorySliderHighlight = React.lazy(
  () => import('../Components/CategorySliderHighlight/CategorySliderHighlight'),
);
const CategorySlider = React.lazy(
  () => import('../Components/CategorySlider/CategorySlider'),
);
const ContentSlider = React.lazy(
  () => import('../Components/ContentSlider/ContentSlider'),
);

const highlightedCarouselStyle: StyleProp<ViewStyle> = {
  marginTop: Metrics.verticalScale(6),
  marginBottom: Metrics.verticalScale(22),
};
const useSectionPageUtils = () => {
  const navigation = useAppNavigation();
  const tracker = useTracker();
  const {t} = useTranslation('translation');
  const {findProgression} = useFindProgression();
  const {calculateProgression} = useLiveEventProgression();
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const dispatch = useDispatch();
  const {mapCourseCategItems} = useMapCourseCateg();
  const {mapQuizzSurvey} = useMapQuizzSurvey();
  const menuTitles: string[] = ['Watch', 'Listen', 'Learn'];
  //   handler
  const handleButtonHeader = useCallback(
    async (item: HeroSliderData, contentSource: ContentSource) => {
      let page = contentSource ? menuTitles[0] : '';
      if (contentSource === 'listen') {
        page = menuTitles[1];
      } else if (contentSource === 'learn') {
        page = menuTitles[2];
      }
      if (item.data) {
        tracker.general.cTAClicked({
          type: 'banner',
          name: item.button?.title || '',
          section: page,
          page,
          blockType: 'highlight',
          blockName: item.title || '',
        });
        if (item.data.externalLink) {
          new RouteFactory(
            {
              url: item.data.externalLink,
              __typename: 'ExternalLink',
              id: '',
            },
            navigation,
            dispatch,
          ).navigate();
        } else if (item.data.linkTo) {
          new RouteFactory(
            {
              url: item.data.linkTo.url,
              __typename: item.data.linkTo.__typename,
              id: item.data.linkTo.id,
              liveEventId: item.data.linkTo.liveEventID,
            },
            navigation,
            dispatch,
          ).navigate();
        }
      }
    },
    [tracker],
  );

  const handleClickCourseSlideItem = useCallback((item: ICourseSliderItem) => {
    const {id, url, __typename} = item;
    const factory = new RouteFactory(
      {url: url || '', __typename, id: id || ''},
      navigation,
      dispatch,
    );
    factory.navigate();
  }, []);

  const handleCategorySliderItemClick = (item: ICategorySliderItem) => {
    const {url, title, __typename, liveEventID, accessData, id, vimeoHlsUrl} =
      item;
    const canSee = canSeeContent(accessData.accessType);
    const canPreview = getPremiumPreviewSettings(
      accessData.paywall as IPaywallFragmentFragment,
    )?.canPreview;
    if (canSee || canPreview) {
      // TO DO : source
      const factory = new RouteFactory(
        {
          url,
          __typename,
          liveEventId: liveEventID,
          id,
          vimeoHlsUrl,
          source: 'livestream',
          paywallData: accessData,
        },
        navigation,
        dispatch,
        title,
      );
      factory.navigate();
    } else {
      navigation.navigate(AppRoutesEnum.PAYWALL, {
        state: {
          params: {
            headerTitle: title || '',
            paywall: accessData.paywall,
            id: 'default',
          },
        },
      });
    }
  };
  const handleContentSliderItemClick = (item: IContentSliderItem) => {
    const {url, title, __typename, accessData, id, raw} = item;

    const canSee = canSeeContent(accessData.accessType);
    const canPreview = getPremiumPreviewSettings(
      accessData.paywall as IPaywallFragmentFragment,
    )?.canPreview;
    if (canSee || canPreview) {
      if (
        raw &&
        raw.__typename === 'VODItem' &&
        raw.parent &&
        raw.parent.__typename === 'Collection'
      ) {
        navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
          state: {
            params: {
              type: 'player',
              source: 'vimeo',
              liveEventId: raw.liveEventID || '',
              videoUrl: raw.vimeoHlsUrl,
              title: title || '',
              url,
              paywallData: {
                ...accessData,
              },
              id,
              list: {
                id: raw.parent.id,
              },
            },
          },
        });
      } else {
        const _vimeoHlsUrl =
          raw &&
          (raw.__typename === 'VODItem' ||
            raw.__typename === 'News' ||
            raw.__typename === 'PodcastItem')
            ? raw.vimeoHlsUrl
            : '';
        new RouteFactory(
          {url, __typename, id, vimeoHlsUrl: _vimeoHlsUrl},
          navigation,
          dispatch,
          title,
        ).navigate();
      }
    } else {
      navigation.navigate(AppRoutesEnum.PAYWALL, {
        state: {
          params: {
            headerTitle: title || '',
            paywall: accessData.paywall,
            id: 'default',
          },
        },
      });
    }
    // TODO: tracker content click
    // tracker.contentClick({
    //   url,
    //   typename: __typename,
    // });
  };
  const handleSeeMoreCategorySlider = useCallback(
    (category: {
      url: string;
      id: string;
      __typename: RouteTypename;
      name?: string;
      title?: string;
    }) =>
      () => {
        const {url, __typename, title, id} = category;
        const factory = new RouteFactory(
          {url, __typename, title, id},
          navigation,
          dispatch,
        );
        factory.navigate();

        // TODO: tracker content click
        // tracker.contentClick({
        //   url,
        //   typename: __typename,
        // });
      },
    [],
  );

  const hanldeItemClickToSingle = (
    id: string,
    item: IContentSliderItem,
    itemsSlider: IContentSliderItem[],
    sliderTitle?: string,
    template?: string,
    category?: ICategorySliderProps['category'],
  ) => {
    const {url, title, __typename, accessData} = item;
    const canSee = canSeeContent(accessData.accessType);
    const canPreview = getPremiumPreviewSettings(
      accessData.paywall as IPaywallFragmentFragment,
    )?.canPreview;
    console.log('canSee', {canSee, canPreview});
    if (canSee || canPreview) {
      const factory = new RouteFactory<
        IUmbracoRouteParams & {
          itemsSlider: IContentSliderItem[];
          title: Maybe<string>;
          template?: string;
          category?: ICategorySliderProps['category'];
          id: string;
        }
      >(
        {
          url,
          __typename,
          itemsSlider,
          title: sliderTitle,
          template,
          category,
          id,
        },
        navigation,
        dispatch,
        title,
      );
      factory.navigate();
    } else {
      navigation.navigate(AppRoutesEnum.PAYWALL, {
        state: {
          params: {
            headerTitle: title || '',
            paywall: accessData.paywall,
            id: 'default',
          },
        },
      });
    }
  };

  const handlePartnerSliderItemClick = useCallback(
    (item: IPartenerSliderItem) => {
      const {url, title, __typename, accessData, id} = item;
      const canSee = canSeeContent(accessData.accessType);
      const canPreview = getPremiumPreviewSettings(
        accessData.paywall as IPaywallFragmentFragment,
      )?.canPreview;
      if (canSee || canPreview) {
        const factory = new RouteFactory(
          {url, __typename, id},
          navigation,
          dispatch,
          title,
        );
        factory.navigate();
      } else {
        navigation.navigate(AppRoutesEnum.PAYWALL, {
          state: {
            params: {
              headerTitle: title || '',
              paywall: accessData.paywall,
              id: 'default',
            },
          },
        });
      }
    },
    [canSeeContent, getPremiumPreviewSettings],
  );

  //   renderer
  const renderSectionPage = useCallback(
    (
      sectionPage: ISectionPageContentFragmentFragment,
      contentSource: ContentSource,
      animatedValue?: Animated.Value,
    ): ReactNode[] => {
      const {
        heroSlider,
        pageBlocks: pageBlocksData,
        autoloop,
        loopDelay,
      } = sectionPage;
      const pageBlocks: ReactNode[] = [];
      const outPutContent: ReactNode[] = [];
      let slideCount = 0;

      // pageBlocks
      pageBlocksData?.forEach((block, blockIndex) => {
        if (block) {
          if (block.content.__typename === 'QuizItemsSlider') {
            const items = mapQuizzSurvey(block.content.contentItems);
            const {title, template} = block.content;
            pageBlocks.push(
              <CourseSlider
                key={blockIndex}
                data={items}
                title={title || ''}
                onItemPress={handleClickCourseSlideItem}
                size={template as ILearnHomeCardProps['size']}
              />,
            );
          } else if (block.content.__typename === 'PartnerSlider') {
            const {title, slider, partnerLink} = block.content;
            if (slider && slider.length) {
              let items = mapPartnerSliderItems(slider);
              pageBlocks.push(
                <PartnerSlider
                  key={blockIndex}
                  title={title || ''}
                  data={items}
                  onItemPress={handlePartnerSliderItemClick}
                  isLink={!!partnerLink}
                  onSeeMore={() => {
                    tracker.general.cTAClicked({
                      type: 'carousel',
                      name: t('See more') || '',
                      section: contentSource,
                      page: contentSource,
                      blockType: 'carousel',
                      blockName: title || '',
                    });
                    partnerLink && partnerLink.__typename === 'PartnersPage'
                      ? handleSeeMoreCategorySlider({
                          id: partnerLink.id || '',
                          url: partnerLink.url || '',
                          __typename: partnerLink.__typename,
                        })
                      : undefined;
                  }}
                />,
              );
            }
          } else if (block.content.__typename === 'CourseCategorySlider') {
            if (block.content.category) {
              const {category, title, isLink, template} = block.content;
              if (
                category.__typename === 'CourseCategory' &&
                category.children &&
                category.children.items
              ) {
                const items = mapCourseCategItems(category.children.items);
                pageBlocks.push(
                  <CourseSlider
                    key={blockIndex}
                    data={items}
                    title={title || ''}
                    onItemPress={handleClickCourseSlideItem}
                    isLink={isLink}
                    size={template as ILearnHomeCardProps['size']}
                    onSeeMore={() => {
                      tracker.general.cTAClicked({
                        type: 'carousel',
                        name: t('See more') || '',
                        section: contentSource,
                        page: contentSource,
                        blockType: 'carousel',
                        blockName: title || '',
                      });
                      handleSeeMoreCategorySlider({
                        id: category.id || '',
                        url: category.url || '',
                        __typename: category.__typename,
                        name: category.name || '',
                        title: category.name || '',
                      })();
                    }}
                  />,
                );
              }
            }
          } else if (block.content.__typename === 'CourseItemsSlider') {
            const {title, contentItems, template} = block.content;
            if (contentItems) {
              const items = mapCourseCategItems(contentItems, true);
              if (items.length) {
                pageBlocks.push(
                  <CourseSlider
                    key={blockIndex}
                    data={items}
                    size={template as ILearnHomeCardProps['size']}
                    title={title || ''}
                    onItemPress={handleClickCourseSlideItem}
                  />,
                );
              }
            }
          } else if (
            block.content.__typename === 'CategorySlider' ||
            block.content.__typename === 'CategorySliderHighlight'
          ) {
            let highlightedData:
              | {
                  surtitle: string;
                  description: string;
                  background: HighlightedCarouselProps['cover'];
                  backgroundDesktop: HighlightedCarouselProps['coverDesktop'];
                }
              | undefined;
            const {showThumbnailTitle, showThumbnailsSubtitle} = block.content;
            if (block.content.__typename === 'CategorySliderHighlight') {
              const {surtitle, description, background, backgroundDesktop} =
                block.content;
              highlightedData = {
                surtitle: surtitle || '',
                description: description || '',
                background: background
                  ? {
                      uri: background.url,
                    }
                  : undefined,
                backgroundDesktop: backgroundDesktop
                  ? {
                      uri: backgroundDesktop.url,
                    }
                  : undefined,
              };
            } else {
              highlightedData = undefined;
            }
            if (block.content.category) {
              const {category, title, isLink, template} = block.content;
              if (category && category.__typename === 'QuizzSurveyCategory') {
                const _category =
                  category as IQuizzSurveyCategoryFragmentFragment;
                const items = mapQuizzSurvey(_category.children.items);
                pageBlocks.push(
                  <CourseSlider
                    key={blockIndex}
                    data={items}
                    title={title || ''}
                    onItemPress={handleClickCourseSlideItem}
                    isLink={isLink}
                    size={template as ILearnHomeCardProps['size']}
                    onSeeMore={() => {
                      tracker.general.cTAClicked({
                        type: 'carousel',
                        name: t('See more') || '',
                        section: contentSource,
                        page: contentSource,
                        blockType: 'carousel',
                        blockName: title || '',
                      });
                      handleSeeMoreCategorySlider({
                        id: _category.id || '',
                        url: _category.url || '',
                        __typename: _category.__typename,
                        name: _category.name || '',
                        title: _category.name || '',
                      })();
                    }}
                  />,
                );
              } else if (
                category.__typename === 'NewsCateg' &&
                category.children &&
                category.children.items
              ) {
                const items = mapNewsCategItems(category.children.items);
                if (items.length) {
                  slideCount += 1;
                }
                if (highlightedData) {
                  pageBlocks.push(
                    <Suspense>
                      <CategorySliderHighlight
                        hideTitle={!showThumbnailTitle}
                        hideSubtitle={!showThumbnailsSubtitle}
                        key={blockIndex}
                        style={highlightedCarouselStyle}
                        tag={highlightedData.surtitle || ''}
                        title={title || ''}
                        description={highlightedData.description || ''}
                        slideTitle={category.name || ''}
                        cover={highlightedData.background}
                        coverDesktop={highlightedData.backgroundDesktop}
                        size={template as ICategorySliderProps['size']}
                        data={items}
                        onItemPress={handleCategorySliderItemClick}
                        onSeeMore={() => {
                          tracker.general.cTAClicked({
                            type: 'carousel',
                            name: t('See more') || '',
                            section: contentSource,
                            page: contentSource,
                            blockType: 'carousel',
                            blockName: title || '',
                          });
                          handleSeeMoreCategorySlider({
                            url: category.url || '',
                            id: category.id || '',
                            __typename: category.__typename,
                            name: category.name || '',
                            title: title || category.name || '',
                          })();
                        }}
                      />
                    </Suspense>,
                  );
                } else {
                  pageBlocks.push(
                    <Suspense>
                      <CategorySlider
                        hideTitle={!showThumbnailTitle}
                        hideSubtitle={!showThumbnailsSubtitle}
                        key={blockIndex}
                        title={title || ''}
                        size={'news'}
                        isLink={isLink}
                        data={items}
                        category={{
                          name: category.name || '',
                          url: category.url || '',
                          id: category.id,
                          __typename: category.__typename,
                        }}
                        onItemPress={handleContentSliderItemClick}
                        onSeeMore={() => {
                          tracker.general.cTAClicked({
                            type: 'carousel',
                            name: t('See more') || '',
                            section: contentSource,
                            page: contentSource,
                            blockType: 'carousel',
                            blockName: title || '',
                          });
                          handleSeeMoreCategorySlider({
                            id: category.id || '',
                            url: category.url || '',
                            __typename: category.__typename,
                            name: category.name || '',
                            title: category.name || '',
                          })();
                        }}
                      />
                    </Suspense>,
                  );
                }
              } else if (
                category.__typename === 'WatchCateg' ||
                category.__typename === 'RadioCateg' ||
                category.__typename === 'PodcastCateg'
              ) {
                const items: ICategorySliderItem[] = mapCategoryChildrenItems(
                  category.children,
                  findProgression,
                  canSeeContent,
                  calculateProgression,
                  {
                    showCover:
                      (template as ICategorySliderProps['size']) === 'top10' &&
                      category.__typename === 'RadioCateg',
                  },
                );
                if (items.length) {
                  slideCount += 1;
                }
                if (items[0] && items[0].__typename === 'Podcast') {
                  pageBlocks.push(
                    highlightedData ? (
                      <Suspense>
                        <CategorySliderHighlight
                          hideTitle={!showThumbnailTitle}
                          hideSubtitle={!showThumbnailsSubtitle}
                          key={blockIndex}
                          style={highlightedCarouselStyle}
                          tag={highlightedData.surtitle || ''}
                          title={title || ''}
                          description={highlightedData.description || ''}
                          slideTitle={category.name || ''}
                          cover={highlightedData.background}
                          coverDesktop={highlightedData.backgroundDesktop}
                          size={template as ICategorySliderProps['size']}
                          data={items}
                          category={{
                            name: category.name || '',
                            url: category.url || '',
                            id: category.id,
                            __typename: category.__typename,
                          }}
                          onItemPress={handleCategorySliderItemClick}
                          onSeeMore={() => {
                            tracker.general.cTAClicked({
                              type: 'carousel',
                              name: t('See more') || '',
                              section: contentSource,
                              page: contentSource,
                              blockType: 'carousel',
                              blockName: title || '',
                            });
                            handleSeeMoreCategorySlider({
                              url: category.url || '',
                              id: category.id || '',
                              __typename: category.__typename,
                              name: category.name || '',
                              title: title || category.name || '',
                            });
                          }}
                        />
                      </Suspense>
                    ) : (
                      <Suspense>
                        <CategorySlider
                          hideTitle={!showThumbnailTitle}
                          hideSubtitle={!showThumbnailsSubtitle}
                          key={blockIndex}
                          data={items}
                          category={{
                            name: category.name || '',
                            url: category.url || '',
                            id: category.id,
                            __typename: category.__typename,
                          }}
                          title={title || category.name || ''}
                          isLink={isLink}
                          size={template as ICategorySliderProps['size']}
                          onItemPress={item => {
                            !!item &&
                              hanldeItemClickToSingle(
                                item.id,
                                item,
                                items,
                                title || category.name || '',
                                template,
                                {
                                  name: category.name || '',
                                  url: category.url || '',
                                  id: category.id,
                                  __typename: category.__typename,
                                },
                              );
                          }}
                          onSeeMore={() => {
                            tracker.general.cTAClicked({
                              type: 'carousel',
                              name: t('See more') || '',
                              section: contentSource,
                              page: contentSource,
                              blockType: 'carousel',
                              blockName: title || '',
                            });
                            handleSeeMoreCategorySlider({
                              url: category.url || '',
                              __typename: category.__typename,
                              name: category.name || '',
                              title: title || category.name || '',
                              id: category.id || '',
                            })();
                          }}
                        />
                      </Suspense>
                    ),
                  );
                } else if (items) {
                  pageBlocks.push(
                    highlightedData ? (
                      <Suspense>
                        <CategorySliderHighlight
                          hideTitle={!showThumbnailTitle}
                          hideSubtitle={!showThumbnailsSubtitle}
                          key={blockIndex}
                          style={highlightedCarouselStyle}
                          tag={highlightedData.surtitle || ''}
                          title={title || ''}
                          description={highlightedData.description || ''}
                          slideTitle={category.name || ''}
                          cover={highlightedData.background}
                          coverDesktop={highlightedData.backgroundDesktop}
                          category={{
                            name: category.name || '',
                            url: category.url || '',
                            id: category.id,
                            __typename: category.__typename,
                          }}
                          size={template as ICategorySliderProps['size']}
                          data={items}
                          onItemPress={handleCategorySliderItemClick}
                          onSeeMore={() => {
                            tracker.general.cTAClicked({
                              type: 'carousel',
                              name: t('See more') || '',
                              section: contentSource,
                              page: contentSource,
                              blockType: 'carousel',
                              blockName: title || '',
                            });
                            handleSeeMoreCategorySlider({
                              url: category.url || '',
                              id: category.id || '',
                              __typename: category.__typename,
                              name: category.name || '',
                              title: title || category.name || '',
                            })();
                          }}
                        />
                      </Suspense>
                    ) : (
                      <Suspense>
                        <CategorySlider
                          hideTitle={!showThumbnailTitle}
                          hideSubtitle={!showThumbnailsSubtitle}
                          key={blockIndex}
                          data={items}
                          category={{
                            name: category.name || '',
                            url: category.url || '',
                            id: category.id,
                            __typename: category.__typename,
                          }}
                          title={title || category.name || ''}
                          isLink={isLink}
                          size={template as ICategorySliderProps['size']}
                          onItemPress={handleCategorySliderItemClick}
                          onSeeMore={() => {
                            tracker.general.cTAClicked({
                              type: 'carousel',
                              name: t('See more') || '',
                              section: contentSource,
                              page: contentSource,
                              blockType: 'carousel',
                              blockName: title || '',
                            });
                            handleSeeMoreCategorySlider({
                              url: category.url || '',
                              id: category.id || '',
                              __typename: category.__typename,
                              name: category.name || '',
                              title: title || category.name || '',
                            })();
                          }}
                        />
                      </Suspense>
                    ),
                  );
                }
              }
            }
          } else if (
            block.content.__typename === 'ContentSlider' ||
            block.content.__typename === 'ContentSliderHighlight'
          ) {
            const {showThumbnailTitle, showThumbnailsSubtitle} = block.content;
            let highlightedData:
              | {
                  surtitle: string;
                  description: string;
                  background: HighlightedCarouselProps['cover'];
                  backgroundDesktop: HighlightedCarouselProps['coverDesktop'];
                }
              | undefined;
            if (block.content.__typename === 'ContentSliderHighlight') {
              const {surtitle, description, background, backgroundDesktop} =
                block.content;
              highlightedData = {
                surtitle: surtitle || '',
                description: description || '',
                background: background
                  ? {
                      uri: background.url,
                    }
                  : undefined,
                backgroundDesktop: backgroundDesktop
                  ? {
                      uri: backgroundDesktop.url,
                    }
                  : undefined,
              };
            } else {
              highlightedData = undefined;
            }

            const {title, template, contentItems} = block.content;

            const items: IContentSliderItem[] = mapContentItems(
              contentItems,
              findProgression,
              canSeeContent,
              calculateProgression,
              {t},
            );

            if (items.length) {
              slideCount += 1;
            }

            if (items[0] && items[0].__typename === 'Podcast') {
              pageBlocks.push(
                highlightedData ? (
                  <Suspense>
                    <HighlightedCarousel
                      hideTitle={!showThumbnailTitle}
                      hideSubtitle={!showThumbnailsSubtitle}
                      key={blockIndex}
                      style={highlightedCarouselStyle}
                      tag={highlightedData.surtitle || ''}
                      title={title || ''}
                      description={highlightedData.description || ''}
                      slideTitle={title || ''}
                      cover={highlightedData.background}
                      coverDesktop={highlightedData.backgroundDesktop}
                      size={template as ICategorySliderProps['size']}
                      data={items}
                      premiumText={t('premium') + ''}
                      onItemPress={(item, index) =>
                        handleContentSliderItemClick(item as IContentSliderItem)
                      }
                    />
                  </Suspense>
                ) : (
                  <Suspense>
                    <ContentSlider
                      hideTitle={!showThumbnailTitle}
                      hideSubtitle={!showThumbnailsSubtitle}
                      key={blockIndex}
                      title={title || ''}
                      size={template as ICategorySliderProps['size']}
                      data={items}
                      onItemPress={item => {
                        !!item &&
                          hanldeItemClickToSingle(
                            item.id,
                            item,
                            items,
                            title,
                            template,
                          );
                      }}
                      onSeeMore={() => {
                        tracker.general.cTAClicked({
                          type: 'carousel',
                          name: t('See more') || '',
                          section: contentSource,
                          page: contentSource,
                          blockType: 'carousel',
                          blockName: title || '',
                        });
                        handleSeeMoreCategorySlider({
                          url: items[0].url || '',
                          id: items[0].id || '',
                          __typename: items[0].__typename,
                          name: items[0].title || '',
                          title: items[0].title || '',
                        })();
                      }}
                    />
                  </Suspense>
                ),
              );
            } else if (items) {
              pageBlocks.push(
                highlightedData ? (
                  <Suspense>
                    <HighlightedCarousel
                      hideTitle={!showThumbnailTitle}
                      hideSubtitle={!showThumbnailsSubtitle}
                      key={blockIndex}
                      style={highlightedCarouselStyle}
                      tag={highlightedData.surtitle || ''}
                      title={title || ''}
                      description={highlightedData.description || ''}
                      slideTitle={title || ''}
                      cover={highlightedData.background}
                      coverDesktop={highlightedData.backgroundDesktop}
                      size={template as ICategorySliderProps['size']}
                      data={items}
                      premiumText={t('premium') + ''}
                      onItemPress={(item, index) =>
                        handleContentSliderItemClick(item as IContentSliderItem)
                      }
                    />
                  </Suspense>
                ) : (
                  <Suspense>
                    <ContentSlider
                      hideTitle={!showThumbnailTitle}
                      hideSubtitle={!showThumbnailsSubtitle}
                      key={blockIndex}
                      title={title || ''}
                      size={
                        items[0] && items[0].__typename === 'News'
                          ? 'news'
                          : (template as ICategorySliderProps['size'])
                      }
                      data={items}
                      onItemPress={handleContentSliderItemClick}
                      onSeeMore={() => {
                        tracker.general.cTAClicked({
                          type: 'carousel',
                          name: t('See more') || '',
                          section: contentSource,
                          page: contentSource,
                          blockType: 'carousel',
                          blockName: title || '',
                        });
                        items[0]
                          ? handleSeeMoreCategorySlider({
                              url: items[0].url || '',
                              id: items[0].id || '',
                              __typename: items[0].__typename,
                              name: items[0].title || '',
                              title: items[0].title || '',
                            })()
                          : undefined;
                      }}
                    />
                  </Suspense>
                ),
              );
            }
          } else if (block.content.__typename === 'Push') {
            slideCount += 1;
            pageBlocks.push(
              <PushWrapper key={blockIndex} push={block.content} />,
            );
          } else if (block.content.__typename === 'MulticategoriesSlider') {
            const {showThumbnailTitle, showThumbnailsSubtitle} = block.content;
            const {title, template, items} = mapMultiCategoryItems(
              block.content,
            );

            if (items.length) {
              slideCount += 1;
            }

            pageBlocks.push(
              <Suspense>
                <ContentSlider
                  hideTitle={!showThumbnailTitle}
                  hideSubtitle={!showThumbnailsSubtitle}
                  key={blockIndex}
                  title={title || ''}
                  size={template as ICategorySliderProps['size']}
                  data={items}
                  onItemPress={handleContentSliderItemClick}
                />
              </Suspense>,
            );
          } else if (block.content.__typename === 'ContextualContentSlider') {
            const {title, template, type} = block.content;
            const {showThumbnailTitle, showThumbnailsSubtitle} = block.content;
            slideCount += 1;
            pageBlocks.push(
              <Suspense>
                <ContextualContentSlider
                  hideTitle={!showThumbnailTitle}
                  hideSubtitle={!showThumbnailsSubtitle}
                  key={blockIndex}
                  title={title || ''}
                  size={template as ICategorySliderProps['size']}
                  type={type as EContextualContentSliderType}
                  onItemPress={handleContentSliderItemClick}
                  contentSource={contentSource}
                />
              </Suspense>,
            );
          } else if (block.content.__typename === 'PagecontentSlider') {
            const {
              title,
              isLink,
              page,
              showThumbnailTitle,
              showThumbnailsSubtitle,
            } = block.content;

            if (page) {
              if (page.__typename === 'LiveTVPage') {
                const items: ICategorySliderItem[] = mapLiveTVPage(
                  page,
                  canSeeContent,
                );
                if (items.length) {
                  slideCount += 1;
                }
                pageBlocks.push(
                  <Suspense>
                    <CategorySlider
                      hideTitle={!showThumbnailTitle}
                      hideSubtitle={!showThumbnailsSubtitle}
                      key={blockIndex}
                      data={items}
                      id={page.id}
                      // category={{
                      //   name: page.name || '',
                      //   url: page.url || '',
                      // }}
                      title={title || page.name || ''}
                      isLink={isLink}
                      size={'channel'}
                      onItemPress={handleCategorySliderItemClick}
                      onSeeMore={() => {
                        tracker.general.cTAClicked({
                          type: 'carousel',
                          name: t('See more') || '',
                          section: contentSource,
                          page: contentSource,
                          blockType: 'carousel',
                          blockName: title || '',
                        });
                        handleSeeMoreCategorySlider({
                          url: page.url || '',
                          id: page.id || '',
                          __typename: page.__typename,
                          name: page.name || '',
                          title: title || page.name || '',
                        })();
                      }}
                    />
                  </Suspense>,
                );
              } else if (page.__typename === 'LiveEventsPage') {
                const items: ICategorySliderItem[] = mapLiveEventsPage(
                  page,
                  canSeeContent,
                  t,
                );
                if (items.length) {
                  slideCount += 1;
                }

                pageBlocks.push(
                  <Suspense>
                    <CategorySlider
                      hideTitle={!showThumbnailTitle}
                      hideSubtitle={!showThumbnailsSubtitle}
                      key={blockIndex}
                      data={items}
                      id={page.id}
                      // category={{
                      //   name: page.name || '',
                      //   url: page.url || '',
                      // }}
                      title={title || page.name || ''}
                      isLink={isLink}
                      size={'big'}
                      onItemPress={handleCategorySliderItemClick}
                      onSeeMore={() => {
                        tracker.general.cTAClicked({
                          type: 'carousel',
                          name: t('See more') || '',
                          section: contentSource,
                          page: contentSource,
                          blockType: 'carousel',
                          blockName: title || '',
                        });
                        handleSeeMoreCategorySlider({
                          url: page.url || '',
                          id: page.id || '',
                          __typename: page.__typename,
                          name: page.name || '',
                          title: title || page.name || '',
                        })();
                      }}
                    />
                  </Suspense>,
                );
              }
            }
          } else {
            slideCount += 1;
            outPutContent.push(
              <View key={blockIndex}>
                <Typography variant="ui3">
                  {block.content.__typename}
                </Typography>
              </View>,
            );
          }
        }
      });

      // heroSlider
      const heroSlidesList: IItemAppCarouselProps[] = [];
      heroSlider?.forEach(heroItem => {
        if (
          heroItem &&
          heroItem.content &&
          heroItem.content.__typename === 'HeroSlide'
        ) {
          const mappedContent = mapHeroSlide(heroItem.content);
          heroSlidesList.push({
            ...mappedContent,
            partner: mappedContent.partner && {},
            backgroundUrl:
              typeof mappedContent.image === 'object'
                ? mappedContent.image.uri
                : '',
            desktopBackgroundUrl:
              typeof mappedContent.desktopImage === 'object'
                ? mappedContent.desktopImage.uri
                : '',
            title: mappedContent.title,
            subtitle: mappedContent.description,
            tags: mappedContent.categorie,
            buttonTitle: mappedContent.button?.title || '',
            buttonProps: {
              onPress: () => handleButtonHeader(mappedContent, contentSource),
            },
          });
        } else if (
          heroItem &&
          heroItem.content &&
          heroItem.content.__typename === 'LearnHeroSlide'
        ) {
          const mappedContent = mapLearnHeroSlide(heroItem.content, t);
          heroSlidesList.push({
            ...mappedContent,
            partner: mappedContent.partner || {},
            backgroundUrl:
              typeof mappedContent.image === 'object'
                ? mappedContent.image.uri
                : '',
            desktopBackgroundUrl:
              typeof mappedContent.desktopImage === 'object'
                ? mappedContent.desktopImage.uri
                : '',
            title: mappedContent.title,
            subtitle: mappedContent.description,
            tags: mappedContent.categorie,
            buttonTitle: mappedContent.button?.title || '',
            buttonProps: {
              onPress: () => handleButtonHeader(mappedContent, contentSource),
            },
          });
        }
      });
      heroSlidesList.length &&
        outPutContent.push(
          <Suspense>
            <AppCarousel
              key="heroslider"
              aspectRatio={[3 / 4, 21 / 9]}
              items={heroSlidesList}
              navigationPosition={'bottom'}
              // autoLoop={autoloop}
              // interval={loopDelay}
            />
          </Suspense>,
        );
      // <HeroSlider
      //   key="heroslider"
      //   autoLoop={autoloop}
      //   interval={loopDelay}
      //   data={heroSlidesList}
      //   animatedScroll={slideCount > 10 ? animatedValue : undefined}
      //   onPressPlayButton={handleButtonHeader}
      //   wrapperStyle={{
      //     marginBottom: Metrics.verticalScale(-7),
      //   }}
      // />,

      return [...outPutContent, ...pageBlocks];
    },
    [],
  );
  return {
    renderSectionPage,
    handleCategorySliderItemClick,
    handleContentSliderItemClick,
    handleSeeMoreCategorySlider,
    hanldeItemClickToSingle,
    handlePartnerSliderItemClick,
    handleClickCourseSlideItem,
    handleButtonHeader,
  };
};

export default useSectionPageUtils;
